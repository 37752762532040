import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CART_COUNT, SET_CART_COUNT } from "./actionTypes"
import { fetchCartCount } from "common/API Endpoints/commonAPI";
import { getCartCount } from "./actions"


function* getCartValue() {
    try {
        const response = yield call(fetchCartCount);
        yield put(getCartCount(response))
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchCart() {
    // yield takeEvery(CART_COUNT, getCartValue)
    yield takeEvery(SET_CART_COUNT, getCartValue)
}

function* CartSaga() {
    yield all([fork(watchCart)])
}

export default CartSaga
