import { useSelector } from 'react-redux';
import { all } from 'redux-saga/effects';

export const convertMoney = moneyValue => {
  var value = moneyValue;
  value = value.toString();
  var afterPoint = '';
  var res = '';
  var lastThree = '';
  var otherNumbers = '';
  if (value.indexOf('.') > 0) {
    afterPoint = value.substring(value.indexOf('.'), value.length);
    value = Math.floor(value);
    value = value.toString();
    lastThree = value.substring(value.length - 3);
    otherNumbers = value.substring(0, value.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      lastThree +
      afterPoint;
  } else {
    lastThree = value.substring(value.length - 3);
    otherNumbers = value.substring(0, value.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  }

  return res;
};
export const calcConvertMoney = price => {
  const rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    decimalDigits: 0,
    useGrouping: true,
  });
  return rupee.format(price);
};
export function convertToWords(value) {
  var fraction = Math.round(frac(value) * 100);
  var f_text = '';
  if (fraction == 1) {
    f_text = ' and ' + convert_number(fraction) + ' Paisa';
  }
  if (fraction > 1) {
    f_text = ' and ' + convert_number(fraction) + ' Paise';
  }
  if (Math.floor(value) == 1) {
    return convert_number(value) + ' Rupee' + f_text;
  } else {
    return convert_number(value) + ' Rupees' + f_text;
  }
}
function frac(f) {
  return f % 1;
}
function convert_number(number) {
  if (number < 0 || number > 999999999) {
    return 'NUMBER TOO LARGE TO CONVERT!';
  }
  var Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  var kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  var Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  var Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  var tn = Math.floor(number / 10);
  var one = Math.floor(number % 10);
  var res = '';
  if (Gn > 0) {
    res += convert_number(Gn) + ' Crore';
  }
  if (kn > 0) {
    res += (res == '' ? '' : ' ') + convert_number(kn) + ' Lakh';
  }
  if (Hn > 0) {
    res += (res == '' ? '' : ' ') + convert_number(Hn) + ' Thousand';
  }
  if (Dn) {
    res += (res == '' ? '' : ' ') + convert_number(Dn) + ' Hundred';
  }
  var ones = Array(
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  );
  var tens = Array(
    '',
    '',
    'Twenty',
    'Thirty',
    'Fourty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  );
  if (tn > 0 || one > 0) {
    if (!(res == '')) {
      res += ' and ';
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += '-' + ones[one];
      }
    }
  }
  if (res == '') {
    res = 'Zero';
  }
  return res;
}
export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function (...args) {
    if (!lastRan) {
      func.apply(this, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(this, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
// const debounce = (func, delay) => {
//   let debounceTimer;
//   return function (...args) {
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func.apply(this, args), delay);
//   };
// };
export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// export const isUserAllowed = (hostname, userProfile) => {
//   const allowedHostnames = ['stage.app.investifyd.com', 'localhost'];
//   const allowedInsIds = [21, 1, 35, 37];
//   //const allowedInsIds = [21, 1, 35, 37, 65, 143];
//   const userInsId = userProfile?.insId;
//   const localStorageInvestorId = parseInt(
//     localStorage.getItem('investorId'),
//     10
//   );

//   if (
//     allowedInsIds.includes(userInsId) ||
//     allowedInsIds.includes(localStorageInvestorId)
//   ) {
//     if (
//       hostname === 'app.investifyd.com' ||
//       allowedHostnames.includes(hostname)
//     ) {
//       return true;
//     }
//   }

//   return false;
// };

// export const isUserAllowed = (hostname, userProfile) => {
//   // Hostnames that are allowed for the stage environment
//   const stageHostnames = ['stage.app.investifyd.com', 'localhost'];

//   // Institution IDs allowed for the stage environment
//   const allowedInsIdsStage = [65, 1, 21];

//   // Institution IDs allowed for the app environment
//   const allowedInsIdsApp = [21, 1, 35, 37];

//   // Retrieve the user's institution ID from the profile
//   const userInsId = userProfile?.insId;

//   // Retrieve the investor ID from local storage and convert it to an integer
//   const localStorageInvestorId = parseInt(
//     localStorage.getItem('investorId'),
//     10
//   );

//   // Determine which list of allowed institution IDs to use based on the hostname
//   const allowedInsIds = stageHostnames.includes(hostname)
//     ? allowedInsIdsStage
//     : hostname === 'app.investifyd.com'
//     ? allowedInsIdsApp
//     : [];

//   // Check if the user's institution ID or the local storage investor ID is in the allowed list
//   return (
//     allowedInsIds.includes(userInsId) ||
//     allowedInsIds.includes(localStorageInvestorId)
//   );
// };
export const isUserAllowed = (hostname, userProfile) => {
  // Institution IDs allowed for the app environment
  const allowedInsIdsApp = [21, 1, 35, 37, 59, 161];

  // Retrieve the user's institution ID from the profile
  const userInsId = userProfile?.insId;

  // Retrieve the investor ID from local storage and convert it to an integer
  const localStorageInvestorId = parseInt(
    localStorage.getItem('investorId'),
    10
  );

  // Check if the hostname is 'app.investifyd.com'
  if (hostname === 'app.investifyd.com') {
    // For the app environment, check if the IDs are in the allowed list
    return (
      allowedInsIdsApp.includes(userInsId) ||
      allowedInsIdsApp.includes(localStorageInvestorId)
    );
  } else {
    // For other environments (stage, localhost), allow all IDs
    return true;
  }
};
